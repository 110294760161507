
.questionText {
  font-weight: bold;
}
.questionTextEm {
  font-style: italic;
  font-size: 0.75em;
  margin-top: -0.5em;
}

@media (min-width: 768px) {
  .questionTextEm {
    display: none;
  }
}

.errorState {
  background-color: #ff00006c;
  padding: 1.5em 1em;
  display: none;
  margin: 1.5em 0 0;
}

.errorStateActive {
  display: block;
}

.closedQuestion {
  display: block;
  margin: 0.75em 0;
}

.closedAnswer {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 1.75rem;
  height: 1.75rem;
  margin: 0.25em 0.5em 0.25em 0;

  border: 2px solid #707070;
  border-radius: 100%;
  vertical-align: middle;

  position: relative;
}

.closedAnswer:checked::before {
  position: absolute;
  background-color: #2A2C2B;
  left: 0.25em;
  right: 0.25em;
  top: 0.25em;
  bottom: 0.25em;
  border-radius: 100%;
  content: " ";
}

.closedAnswer[type="checkbox"],
.closedAnswer[type="checkbox"]:checked::before {
  border-radius: 0;
}

.startMonth {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

@media (min-width: 768px) {
  .startMonth {
    width: 70%;
  }
}

.monthsRange,
.monthsText {
  display: inline-block;
  padding: 0;
  vertical-align: middle;
}
.monthsRange {
  width: 70%;
  margin: 1em 0;
}

.monthsText {
  width: 30%;
  margin: 0;
  line-height: 1;
  text-align: center;
}

.monthsValue,
.monthsUnit {
  display: block;
}

.monthsValue {
  font-size: 2em;
}

@media (min-width: 768px) {
  .moodGraphQuestion {
    grid-column: 1 / 3;
  }
}

.submitArea {
  position: relative;
}

.submitBtn {
  -webkit-appearance: none;
  width: 100%;
  font-size: 2em;
  font-weight: bold;
  padding: 0.5em 2em;
  background: #0E3B80;
  color: white;
  border-radius: 0.5em;

  margin: 0 0 1em 0;

  transition: padding 800ms ease-in-out;
}

.submitIcon {
  position: absolute;
  right: 2em;
  top: 1em;
}

.submitTick {
  top: 1.25em;
}

.pending {
  background: rgb(98, 103, 110);
  padding: 0.5em 4em 0.5em 0;
}

.formError {
  composes: errorState;
  display: block;
  margin: 1em 0;
}

.submitError {
  composes: formError;
}

.submitted {
  background-color: #31A24B;
  padding: 0.5em 4em 0.5em 0;
}

@media (min-width: 768px) {

  .submitArea,
  .disclaim {
    align-self: center;
  }

  .submitArea {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
    margin: 0;
  }
  .disclaim {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
  }  
}