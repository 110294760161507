.question {
  margin: 0 0 2rem 0;
}

@media (min-width: 768px) {
  .question {
    margin: -4em 0 4rem 0;
  }
}
.heading {
  font-size: 2rem;
  font-weight: normal;
  padding: 0;
  margin: 0;
  line-height: 1.05;
}

.questionText {
  font-weight: bold;
}