.body {
  padding: 1.75em 1em 0 1em;
}

.footer {
  background-image: url(../../img/survey-footer-background.svg);
  background-color: #fcfcfc;
  background-size: 135%;
  background-position: center 3rem;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  margin: -4.25em 0 0 0;
  padding: 5rem 0 2rem;
}

.links{
  line-height: 1em;
  font-size: 0.5em;
}

.footerLink {
  position: relative;
  padding: 0 0.75em;
}
.footerLink:after {
  content: " ";
  position: absolute;
  bottom: -0.75em;
  left: 0.5em;
  right: 0.5em;
  height: 0.25em;
  background-color: white;
  opacity: 0;

  transition: all 400ms ease-in-out;
}

.footerLink:hover:after {
  opacity: 1;
}

.kashisLogoLink {
  composes: footerLink;
  border-right: 1px solid #ffffff88;
}
.codeLink {
  composes: footerLink;
  color: white;
}
.licenseLink {
  composes: footerLink;
  border-left: 1px solid #ffffff88;
}

.logoImage {
  height: 2em;
  margin: 0;
  vertical-align: text-bottom;
}

.kashisLogoImage,
.codeLinkImage,
.licenseLinkImage {
  composes: logoImage;  
}

