.range {
  -webkit-appearance: none;
  margin: 13.5px 0;
}
.range:focus {
  outline: none;
}
.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0.9px 0.9px 1.7px #002200, 0px 0px 0.9px #003c00;
  background: rgba(42, 44, 43, 0.5);
  border-radius: 1px;
  border: 0px solid rgba(42, 44, 43, 0.25);
}
.range::-webkit-slider-thumb {
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.45), 0px 0px 1px rgba(13, 13, 13, 0.45);
  border: 1px solid rgba(42, 44, 43, 0.25);
  height: 28px;
  width: 40px;
  border-radius: 50px;
  background: #1b76ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -13.5px;
}
.range:focus::-webkit-slider-runnable-track {
  background: rgba(77, 81, 79, 0.5);
}
.range::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0.9px 0.9px 1.7px #002200, 0px 0px 0.9px #003c00;
  background: rgba(42, 44, 43, 0.5);
  border-radius: 1px;
  border: 0px solid rgba(42, 44, 43, 0.25);
}
.range::-moz-range-thumb {
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.45), 0px 0px 1px rgba(13, 13, 13, 0.45);
  border: 1px solid rgba(42, 44, 43, 0.25);
  height: 28px;
  width: 40px;
  border-radius: 50px;
  background: #1b76ff;
  cursor: pointer;
}
.range::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.range::-ms-fill-lower {
  background: rgba(7, 7, 7, 0.5);
  border: 0px solid rgba(42, 44, 43, 0.25);
  border-radius: 2px;
  box-shadow: 0.9px 0.9px 1.7px #002200, 0px 0px 0.9px #003c00;
}
.range::-ms-fill-upper {
  background: rgba(42, 44, 43, 0.5);
  border: 0px solid rgba(42, 44, 43, 0.25);
  border-radius: 2px;
  box-shadow: 0.9px 0.9px 1.7px #002200, 0px 0px 0.9px #003c00;
}
.range::-ms-thumb {
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.45), 0px 0px 1px rgba(13, 13, 13, 0.45);
  border: 1px solid rgba(42, 44, 43, 0.25);
  height: 28px;
  width: 40px;
  border-radius: 50px;
  background: #1b76ff;
  cursor: pointer;
  height: 1px;
}
.range:focus::-ms-fill-lower {
  background: rgba(42, 44, 43, 0.5);
}
.range:focus::-ms-fill-upper {
  background: rgba(77, 81, 79, 0.5);
}
