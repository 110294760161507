.tableSection {
  grid-column: 1 / 3;
}
.dataTable {
  width: 100%;
}

.header {
  font-weight: bold;
}

.numericalData {
  text-align: right;
}