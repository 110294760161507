.survey {
  margin: 2rem 0;
}
.divider {
  background-image: url('../../img/survey-background.svg');
  background-size: 111%;
  background-position: top center;
  height: 20vw;
  border: 0 none;
  margin: 0 -1rem;
  padding: 1rem;
}
.content {
  background-color: #fcfcfc;
  margin: -3rem -1rem 4rem;
  padding: 0 1rem;
  display: grid;
}

.bodyCentre {
  text-align: center;
  grid-column: 1 / 3;
}

.serverError {
  text-align: center;
  grid-column: 1 / 3;

  background-color: rgb(255, 173, 134);

  padding: 1rem;
}

.btn {
  -webkit-appearance: none;
  width: 100%;
  font-size: 2em;
  font-weight: bold;
  padding: 0.5em 2em;
  background: #0E3B80;
  color: white;
  border-radius: 0.5em;

  margin: 0 0 1em 0;

  transition: padding 800ms ease-in-out;
}

.pageHeading {
  text-align: center;
  grid-column: 1 / 3;
}

@media (min-width: 768px) {
  .divider {
    margin: 0 -1rem 1rem;
    padding: 0rem;
  }
  .bodyCentre {
    grid-column: 1 / 3;
    margin-bottom: 10rem;
  }
  .pageHeading {
    margin: -3rem 0 0;
  }
  .content {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2em;
    column-gap: 2em;
  }
}

@media (min-width: 1280px) {
  .divider {
    margin: 0 -1rem -3rem;
    padding: 0rem;
  }
  .content {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 10vw;
    padding-right: 10vw;
    padding: 0 10vw 4em;
    row-gap: 2em;
    column-gap: 2em;
  }
}
